@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #AAD914;

.RootView {
    height: 100%;
    min-height: 100vh;
}

.Layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__main {
        flex-grow: 1;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

body {
    background-image: url('./assets/bg-pattern.svg');
    background-color: #e9e9e9;
}
