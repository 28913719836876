$nice-dates-color-gray-dark: #333;
$nice-dates-color-gray: #999;
$nice-dates-color-gray-light: #ddd;
$nice-dates-color-accent: $nice-dates-color-gray-dark;
$nice-dates-font-size-small: 12px;
$nice-dates-font-size-base: 14px;
$nice-dates-font-size-big: 16px;

$nice-dates-cell-width: 40px;

@import '~react-nice-dates/src/style.scss';

.nice-dates {
    width: 100%;
}
