.Appointments {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;

    &__list {
        width: 480px;
        border-right: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        background: white;
    }

    &__videoChat {
        width: calc(100% - 480px);
        position: relative;
    }
}
